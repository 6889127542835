<template>
  <div class="help">
    {{$t('auth.If you have any questions, please contact support')}}:
    <TLink
      :is-nuxt-link="false"
      :is-underlined="false"
      route="mailto:help@alltrucks.online"
    >
      help@alltrucks.online
    </TLink>
    <TLink
      :is-nuxt-link="false"
      :is-underlined="false"
      route="tel:88005507087"
    >
      8(800)550-70-87
    </TLink>
  </div>
</template>

<script>
export default {
  name: 'HelpCenter',
};
</script>

<style lang='scss'>
@import '~/assets/scss/_variables';

.help {
  display: flex;
  flex-direction: column;
  color: $steel-grey;
}
</style>
