<template>
  <div class="quotas-for-all">
    <QuotasInfoHeader @to-login="isPopupOpened = true"/>
    <QuotasInstruction />
    <div class="quotas-table__wrap">
      <div class="mb-3 quotas-header__top">
        <div class="quotas-header__heading">
          <h1 class="pl-4 quotas-header__heading-tilte">
            Доступные квоты на выгрузку
          </h1>
        </div>
      </div>
      <QuotasHeader @to-login="isPopupOpened = true" />
      <QuotasPublicTable />
    </div>
    <QuotasAuthPopup
      :is-popup-opened="isPopupOpened"
      @close="isPopupOpened = false"
    />
  </div>
</template>

<script>
import QuotasAuthPopup from './components/QuotasAuthPopup';
import QuotasHeader from '../Quotas/QuotasHeader';
import QuotasPublicTable from './components/QuotasPublicTable';
import QuotasInfoHeader from '~/components/QuotasPublic/components/QuotasInfoHeader';
import QuotasInstruction from '~/components/QuotasPublic/components/QuotasInstruction';

export default {
  name: 'QuotasPublic',

  components: {
    QuotasInstruction,
    QuotasInfoHeader,
    QuotasHeader,
    QuotasAuthPopup,
    QuotasPublicTable,
  },

  data() {
    return {
      isPopupOpened: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.quotas-for-all {
  background-color: white;
}
.quotas-table__wrap {
  margin-top: 50px;
  margin-left: 38px;
}
</style>
