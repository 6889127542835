// eslint-disable-next-line import/no-extraneous-dependencies
import { mapGetters } from 'vuex';
import { isEmpty } from 'chober';
import moment from 'moment';
import flatten from 'lodash/flatten';

import { UNIT_TYPE_TRIPS } from '../constants';
import routerMixin from './routerMixin';

export default {
  mixins: [routerMixin],

  computed: {
    ...mapGetters({
      availablePeriods: 'quotas/getAvailablePeriods',
      dqMode: 'main/getDqMode',
      unitType: 'quotas/getUnit',
    }),

    allPeriods() {
      return flatten(this.availablePeriods);
    },

    initialFilters() {
      const query = this.getQuery();

      const tech_date = query.tech_date || moment().format('YYYY-MM-DD');
      const direction = Number(query.direction) || this.dqMode;
      const unit_type = Number(query.unit_type) || this.unitType.id;

      return {
        tech_date,
        direction,
        unit_type,
      };
    },
  },

  methods: {
    getTimePeriods(timePeriods) {
      if (!timePeriods || timePeriods.length === 0) {
        return [];
      }
      const list = [];

      this.allPeriods.forEach(period => {
        const foundedPeriod = timePeriods.find(quotaPeriod => quotaPeriod.from === period.from);

        if (foundedPeriod) {
          list.push(foundedPeriod);
        }
      });

      if (list.length === 1) {
        return list;
      }

      const groupedPeriods = [];

      list.reduce((acc, curr, index) => {
        if (acc.to === curr.from) {
          if (list.length === index + 1) {
            groupedPeriods.push({
              from: acc.from,
              to: curr.to,
            });
          }

          return {
            from: acc.from,
            to: curr.to,
          };
        }

        if (!isEmpty(acc)) {
          groupedPeriods.push(acc);
        }

        if (list.length === index + 1) {
          groupedPeriods.push(curr);
        }

        return curr;
      }, {});

      return groupedPeriods;
    },

    formatPeriod(from, to) {
      return `${from} - ${to.replace('00:00', '24:00')}`;
    },

    getCargoPlace(quota) {
      return quota.cargo_station?.name || '';
    },
  },
};
