<template>
  <div class="quotas-instruction__wrapper">
    <div class="quotas-instruction__header">
      Подключение к Цифровой очереди:
    </div>
    <div class="quotas-instruction__content">
      <div class="quotas-instruction__content-item block__first">
        <div class="quotas-instruction__content-item-header">
          <div class="quotas-instruction__content-item-text">
            1. Перейдите
            <TLink
              route="/register"
              target="_blank"
            >
              по ссылке
            </TLink>
             и зарегистрируйте свою компанию.
          </div>
          <TIconSvg
            name="vector"
            class="quotas-instruction__content-item-icon"
            width="35"
            view-box="0 0 20 20"
          />
        </div>
        <div class="quotas-instruction__content-item-body block-body__first">
          <img
            src="/startPageImgs/step1-1.png"
            class="quotas-instruction__content-item-img img-1-1"
          >
          <div class="block-body__first-right">
            <img
              src="/startPageImgs/step1-2.png"
              class="quotas-instruction__content-item-img img-1-2"
            >
            <div class="block-body__first-right-text">
              Если ЦО напишет, что ваша компания уже зарегистрирована, то обратитесь к представителям РЗТ, для получения доступа к своей учетной записи.
            </div>
          </div>
        </div>
      </div>
      <div class="quotas-instruction__content-item block-second">
        <div class="quotas-instruction__content-item-header">
          <div class="quotas-instruction__content-item-text">
            2. Внесите в систему свой
            <span>
              транспорт
              <TIconSvg
                name="car"
                width="25"
                height="20"
                view-box="0 0 20 28"
              />
              и водителей
              <TIconSvg
                name="driver"
                width="20"
                height="20"
                view-box="0 0 20 28"
              />
            </span>
          </div>
          <TIconSvg
            name="vector"
            class="quotas-instruction__content-item-icon"
            width="35"
            view-box="0 0 20 20"
          />
        </div>
        <div class="quotas-instruction__content-item-body block-body__second">
          <img
            src="/startPageImgs/step2-1.png"
            class="quotas-instruction__content-item-img img-2-1"
          >
          <img
            src="/startPageImgs/step2-2.png"
            class="quotas-instruction__content-item-img img-2-2"
          >
        </div>
      </div>
      <div class="quotas-instruction__content-item block-third">
        <div class="quotas-instruction__content-item-header">
          <div class="quotas-instruction__content-item-text">
            3. Оформляйте рейсы на своих водителей, т.е. вносите информацию о перевозке из ТТН и выбирайте любой доступный таймслот.
          </div>
          <TIconSvg
            name="vector"
            class="quotas-instruction__content-item-icon"
            width="35"
            view-box="0 0 20 20"
          />
        </div>
        <img
          src="/startPageImgs/step3-1.png"
          class="quotas-instruction__content-item-img img-3-1"
        >
      </div>
      <div class="quotas-instruction__content-item block__fourth">
        <div class="quotas-instruction__content-item-header">
          <div class="quotas-instruction__content-item-text">
            4. Водителям необходимо скачать приложение для получения информации о таймслоте и управления им
          </div>
          <TIconSvg
            name="vector"
            class="quotas-instruction__content-item-icon"
            width="35"
            view-box="0 0 20 20"
          />
        </div>
        <div class="quotas-instruction__content-item-body block-body__fourth">
          <div>
            <img
              src="/startPageImgs/step4-1-new.jpeg"
              class="quotas-instruction__content-item-img img-4-1"
            >
            <span>только Android</span>
          </div>
          <img
            src="/startPageImgs/step4-2.png"
            class="quotas-instruction__content-item-img img-4-2"
          >
        </div>
      </div>
      <div class="quotas-instruction__content-item block__fifth">
        <div class="quotas-instruction__content-item-header block-header__fifth">
          <div class="quotas-instruction__content-item-text">
            5. Выполнение рейса вы можете контролировать на сайте Цифровой очереди до завершения разгрузки.
          </div>
        </div>
        <img
          src="/startPageImgs/step5-1.png"
          class="quotas-instruction__content-item-img img-5-1"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuotasInstruction',
}
</script>

<style scoped lang="scss">
@import 'assets/scss/_variables';
.quotas-instruction__wrapper {
  background-color: white;
  padding: 10px 10px 38px 38px;
  border: 1px solid black;
  box-shadow: 0 10px 14px rgba(0,0,0,0.25), 0 8px 8px rgba(0,0,0,0.22);
}

.quotas-instruction__header {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 166.667% */
  text-decoration-line: underline;
  padding-bottom: 10px;
}

.quotas-instruction__content {
  display: flex;

  &-item {
    &-header {
      display: flex;
      padding-bottom: 20px;
    }
    &-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      width: 83%;
    }
    &-icon {
      margin-top: 10px;
    }
  }
}

.block__first, .block-second {
  width: 20%;
}

.block-third {
  width: 23%;
}

.block__fourth {
  width: 19%;
}

.block__fifth {
  width: 18%;
}

.block-body__first {
  display: flex;
  justify-content: space-between;

  &-right {
    display: flex;
    flex-direction: column;
    justify-content: left;

    &-text {
      padding-left: 5px;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 109.091% */
    }
  }
}

.block-second {
  & .quotas-instruction__content-item-text > span {
    display: inline-flex;
    align-items: center;

    & svg {
      margin-right: 5px;
    }
  }
}

.block-body__second {
  position: relative;
  width: 100%;
  margin: 0 30px;

  & img:last-child {
    position: absolute;
    top: 60%;
    left: 50px;
  }
}

.block-body__fourth {
  display: flex;
  width: 75%;
  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.block-header__fifth {
  & .quotas-instruction__content-item-text {
    width: 100%;
  }
}

.img-1-1 {
  width: 45%;
}

.img-1-2 {
  width: 80%;
}

.img-2-1, .img-2-2 {
  width: 60%;
}

.img-3-1 {
  width: 80%;
  margin-left: 2%;
}

.img-4-1 {
  width: 80%;
	border: 1px solid black;
}

.img-4-2 {
  width: 43%;
}

.img-5-1 {
  width: 100%;
}

</style>
