<template>
  <div class="auth-locale-selector">
    <TDropdown
      ref="localeDropdown"
      v-model="isDropdownOpen"
    >
      <template #title>
        <div class="auth-locale-selector__wrapper">
          <TIconSvg
            :name="`locales/${$i18n.locale}`"
            class="mr-2"
            width="19"
            height="14"
            view-box="0 0 19 15"
          />
          <span class="mr-2 auth-locale-selector__label">{{$i18n.locale}}</span>
          <TIconSvg
            slot="after"
            class="auth-locale-selector__chevron"
            :class="{'auth-locale-selector__chevron--opened': isDropdownOpen}"
            name="arrow-up"
            width="12"
            height="12"
            view-box="0 0 512 512"
          />
        </div>
      </template>
      <template #content>
        <div class="auth-locale-selector__dropdown">
          <p
            v-for="locale of $i18n.locales"
            :key="locale.code"
            class="auth-locale-selector__row"
            @click="switchLanguage(locale.code)"
          >
            <TIconSvg
              :name="`locales/${locale.code}`"
              class="mr-2"
              width="20"
              height="17"
              view-box="0 0 20 16"
            />
            <span>{{locale.name}}</span>
          </p>
        </div>
      </template>
    </TDropdown>
  </div>
</template>

<script>
// Constants
import { I18N_COOKIE } from '~/assets/js/constants';

export default {
  name: 'AuthLanguageSelector',

  data: () => ({
    isDropdownOpen: false,
  }),

  methods: {
    switchLanguage(newLocaleCode) {
      this.$cookies.set(I18N_COOKIE, newLocaleCode);
      this.$axios.setHeader('Accept-Language', newLocaleCode);
      this.$validator.locale = newLocaleCode;
      this.$router.go();
    },
  },
};
</script>

<style lang="scss">
.auth-locale-selector {
  &__wrapper {
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  &__label {
    font-weight: bold;
    line-height: 14px;
    text-transform: uppercase;
  }

  &__chevron {
    transform: rotate(0deg);
    transition: 0.15s ease-in transform;

    &--opened {
      transform: rotate(180deg);
    }
  }

  &__dropdown {
    padding: 5px 24px;
  }

  &__row {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
