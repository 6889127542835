import moment from 'moment';

export default {
  methods: {
    moment,

    formatDate(date, formatTo = 'DD.MM.YYYY', formatFrom = 'YYYY-MM-DD HH:mm:ss') {
      if (!date) {
        return '';
      }

      return this.moment(date, formatFrom).format(formatTo);
    },

    getCurrentDate(formatTo = 'YYYY-MM-DD HH:mm:ss') {
      return this.moment().format(formatTo);
    },

    formatOrderDate(from, to) {
      if (!from) {
        return '';
      }

      if (from && to) {
        return `${moment(from, 'YYYY-MM-DD').format('DD.MM')} - ${moment(to, 'YYYY-MM-DD').format('DD.MM.YY')}`;
      }

      return moment(from, 'YYYY-MM-DD').format('DD.MM.YY');
    },
  },
};
