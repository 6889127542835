import { clone, isEqual } from 'chober';

function transformValuesToString(rawQuery) {
  return Object.entries(rawQuery).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: Array.isArray(value) ? value : String(value),
  }), {});
}

export default {
  methods: {
    async setQuery(queries = {}, options = { isSaveOld: false, isSaveHash: false, method: 'replace' }) {
      const params = { query: {}, hash: '' };

      if (options.isSaveHash) {
        params.hash = this.getHash();
      }

      if (options.isSaveOld) {
        params.query = this.getQuery();
      }

      Object.assign(params.query, transformValuesToString(queries));

      try {
        if (!isEqual(params.query, this.getQuery())) {
          await this.$router[options.method](params);
        }
      } catch (error) {
        console.log(error);
      }

      return Promise.resolve();
    },

    async removeQuery(queries) {
      const params = { query: {}, hash: '' };

      params.hash = this.getHash();
      params.query = this.getQuery();

      queries.forEach(query => {
        delete params.query[query];
      });

      await this.$router.replace(params);
    },

    getQuery(options = { arrays: [] }) {
      const query = clone(this.$router.history.current.query);

      options.arrays.forEach(key => {
        const value = query[key];

        if (!value || !Array.isArray(value)) {
          query[key] = value ? [value] : [];
        }
      });

      return query;
    },

    getHash() {
      return this.$router.history.current.hash;
    },

    hasDifferQuery(excludedQueries) {
      const query = this.getQuery();

      return Object.keys(query).some(prop => !excludedQueries.includes(prop));
    },
  },
};
