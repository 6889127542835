<template>
  <div class="quotas-info__header">
    <div class="quotas-info__header-logo">
      <img
        src="/logo.png"
        class="quotas-info__header-logo-img"
      >
      <TButton
        class="quotas-header__button"
        @click="$emit('to-login')"
      >
        {{$t('auth.Login or register')}}
      </TButton>
    </div>
    <div class="quotas-info__header-content">
      Для упорядочения въезда грузового автотранспорта на Ростовский Зерновой Терминал реализована Цифровая очередь - программно-аппаратная
      система управления потоками транспорта. Инструкция по работе в цифровой очереди доступна
      <TLink
        :is-nuxt-link="false"
        route="https://drive.google.com/file/d/1Xod8i2rIlr_b4J-Td6DlOg3GujeJQaSl/view?usp=sharing"
        target="_blank"
      >по ссылке</TLink>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'QuotasInfoHeader',

  computed: {
    ...mapGetters({
      isLoggedIn: 'general/isLoggedIn',
    }),
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/_variables';
.quotas-info__header {
  background-color: white;
  padding: 26px 38px;

  &-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-img {
      max-width: 600px;
    }
  }

  &-content {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding-top: 10px;
  }
}

</style>
