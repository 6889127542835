<template>
  <div>
    <QuotasPublic />
  </div>
</template>

<script>
import moment from 'moment';
import QuotasPublic from '@/components/QuotasPublic';

export default {
  name: 'QuotasPublicPage',

  layout: 'empty',

  components: {
    QuotasPublic,
  },

  async fetch({ store, query }) {
    const tech_date = query.tech_date || moment().format('YYYY-MM-DD');
    const direction = 2;

    await Promise.all([
      store.dispatch('quotas/loadLabels', '/guest-quotas/create'),
      store.dispatch('quotas/loadPublicQuotaList', { tech_date, direction }),
    ]);
  },

  head() {
    return {
      title: `${this.$t('quotas.Quotas')} - All Trucks Online`,
    };
  },
};
</script>
