<template>
  <div class="quotas-public-table__wrapper">
    <TButton
      v-if="isShownLeftScrollButton"
      icon="arrow-left"
      color="yellow"
      class="quotas-public-table__arrow quotas-public-table__arrow--left"
      icon-width="7"
      icon-height="12"
      icon-view-box="0 0 7 12"
      icon-stroke="#fff"
      @click="scrollLeft"
    />
    <TButton
      v-if="isShownRightScrollButton"
      icon="arrow-right"
      color="yellow"
      class="quotas-public-table__arrow quotas-public-table__arrow--right"
      icon-width="7"
      icon-height="12"
      icon-view-box="0 0 7 12"
      icon-stroke="#fff"
      @click="scrollRight"
    />
    <client-only>
      <div
        v-if="isTableLoading"
        class="quotas-public-table__preloader-wrapper"
      >
        <TPreloader
          class="quotas-public-table__preloader"
          :color="'#5E6979'"
          size="40"
        />
      </div>
      <div
        v-else-if="quotaList.length"
        ref="quotasTable"
        class="quotas-public-table"
        :class="{
          'quotas-public-table--hasHorizontalShadow': isTableHorizontalScrolled,
          'quotas-public-table--hasVerticalShadow': isTableVerticalScrolled
        }"
        @scroll="tableScrolled"
      >
        <div>
          <div class="quotas-public-table__header">
            <div class="quotas-public-table__header-row">
              <div class="quotas-public-table__col quotas-public-table__col--fixed" />
              <div
                v-for="(product, index) in filteredProducts"
                :key="index"
                class="quotas-public-table__col"
              >
                <div
                  :class="{'opened-products-list': openedProductsList}"
                >
                  <div
                    class="quotas-table__product-name"
                  >
                    {{product.product_type}}
                  </div>
                  <div
                    class="quotas-table__product-type"
                    @click="openProductList(product.products)"
                  >
                    <TIconSvg
                      name="arrow-right"
                      class="products-group__arrow-icon"
                      width="7"
                      height="12"
                      view-box="0 0 7 12"
                    />
                    <span>{{product.products[0].name}}</span>
                  </div>
                  <div
                    v-show="openedProductsList"
                    class="quotas-table__product-list"
                  >
                    <div
                      v-for="(item, index) in product.products"
                      :key="item.id"
                      class="quotas-table__products-list"
                    >
                      <span v-if="index !== 0">
                        {{item.name}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
<!--              <div-->
<!--                v-for="(item, productIndex) in filteredProducts"-->
<!--                :key="productIndex"-->
<!--                class="quotas-public-table__col"-->
<!--              >-->
<!--                {{item.name}}-->
<!--              </div>-->
            </div>
          </div>
          <div class="quotas-public-table__body">
            <div
              v-if="!hasTurns"
              class="quotas-public-table__row"
            >
              <div class="quotas-public-table__row-col quotas-public-table__row-col--fixed">
                {{$t('quotas.Free')}}
              </div>
              <div
                v-for="(quota, quotaIndex) in freeQuotas"
                :key="quotaIndex"
                class="quotas-public-table__row-col"
              >
                <div>
                  <div class="quotas-public-table__col-time">
                    <span class="quotas-table__cargo-place">{{getCargoPlace(quota)}}</span>
                    <span
                      v-for="(item, index) in getTimePeriods(quota.time_periods)"
                      :key="index"
                    >
                      <span v-if="index === 0">
                        {{formatPeriod(item.from, item.to)}}
                      </span>
                    </span>
                    <span
                      v-if="getTimePeriods(quota.time_periods).length > 1"
                      class="quotas-table__col-time-counter"
                    >
                      +{{getTimePeriods(quota.time_periods).length - 1}}
                    </span>
                  </div>
                  <div class="quotas-table__col-section quotas-table__col-section--quota">
                    <span>
                      {{filters.unit_type === 1 ? quota.quantity_trips : quota.quantity_tons}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <template v-else>
              <div
                v-for="(quotaRow, rowIndex) in formattedQuotas"
                :key="rowIndex"
                class="quotas-public-table__row"
              >
                <div
                  class="quotas-public-table__row-col quotas-public-table__row-col--fixed"
                  :class="{'quotas-public-table__row-col--upper': quotaRow.isTotal}"
                >
                  {{quotaRow.name}}
                </div>
                <div
                  v-for="(quota, quotaIndex) in formattedQuotas"
                  :key="quotaIndex"
                  class="quotas-public-table__row-col"
                  :class="{'quotas-public-table__row-col--total': quotaRow.isTotal}"
                >
                  <div class="quotas-public-table__col-time">
                    <span class="quotas-table__cargo-place">{{getCargoPlace(quota)}}</span>
                    <span
                      v-for="(item, index) in getTimePeriods(quota.time_periods)"
                      :key="item.vueKey"
                    >
                      <span v-if="index === 0">
                        {{formatPeriod(item.from, item.to)}}
                      </span>
                    </span>
                    <span
                      v-if="getTimePeriods(quota.time_periods).length > 1"
                      class="quotas-table__col-time-counter"
                    >
                      +{{getTimePeriods(quota.time_periods).length - 1}}
                    </span>
                  </div>
                  <div class="quotas-table__col-section quotas-table__col-section--quota">
                    <span>
                      {{isTripUnit ? quota.quantity_trips : quota.quantity_tons}}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        v-else
        class="quotas-public-table__nodata"
      >
        {{$t('quotas.There are no quotas for the selected date')}}.
      </div>
    </client-only>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';
// Utils
import { groupBy } from '~/assets/js/utils';
// Mixin
import quotasMixin from '~/assets/js/mixins/quotasMixin';
// Constants
import { UNIT_TYPE_TRIPS } from '~/assets/js/constants';

export default {
  name: 'QuotasPublicTable',

  mixins: [quotasMixin],

  data: () => ({
    isShownLeftScrollButton: false,
    isShownRightScrollButton: false,
    isTableHorizontalScrolled: false,
    isTableVerticalScrolled: false,
    openedProductsList: false,
  }),

  computed: {
    ...mapGetters({
      isTableLoading: 'quotas/isTableLoading',
      list: 'quotas/getQuotaList',
      // quotaList: 'quotas/getQuotaList',
      products: 'quotas/getProducts',
      filters: 'quotas/getFilters',
      hasTurns: 'quotas/getHasTurns',
    }),

    quotaList() {
      const list = [];

      Object.keys(this.list).map(key => {
        this.list[key].map(item => {
          list.push({
            ...item,
            product_type: key,
          });
        })
      })

      return list;
    },

    isTripUnit() {
      return this.filters.unit_type === UNIT_TYPE_TRIPS;
    },

    // filteredProducts() {
    //   const list = [];
    //
    //   this.products.forEach(product => {
    //     const hasProduct = this.quotaList.find(quota => quota.product_id === product.id);
    //
    //     if (hasProduct) {
    //       list.push(product);
    //     }
    //   });
    //
    //   return list;
    // },
    filteredProducts() {
      return this.quotaList.map((quota) => {
        return quota.products.length ? {
          ...quota,
          comment: quota.comment,
        } : {
          id: quota.product_type_id,
          products: [],
          deleted: true,
        }
      }).filter((quota, index, array) => {
        const quotasProducts = quota.products.map(product => product.id)
        return !array.filter((qt, idx) => idx < index)
        .filter(qt => qt.products.length === quotasProducts.length)
        .map(qt => qt.products.map(product => product.id))
        .map(productsId => productsId.map(productId => quotasProducts.includes(productId)).includes(false))
        .includes(false);
      });
    },

    formattedQuotas() {
      if (this.hasTurns) {
        return [
          this.getTotalRow(this.quotaList),
          ...this.groupQuotasByTurn(this.quotaList),
        ];
      }
      let list = [];
      this.quotaList.map(item => {
        list.push({
          ...item,
          comment: {
            ...item.comment,
            name: item.comment?.comment,
          },
          companyName: item.companyWithTrashed ? item.companyWithTrashed.name : this.$t('quotas.Free'),
        })
      });

      const tableRows = [];
      const companies = [];
      list.reverse().forEach(quota => {
        let company = companies.find(cmp => cmp.company_id === quota.company_id);
        if(!company) {
          company = {
            company_id: quota.company_id,
            company_name: quota.companyName,
            productTypeGroups: [{
              quotaProducts: quota.products,
              productTypeElements: [quota]
            }]
          };
          companies.push(company);
        } else {
          let productTypeGroup = company.productTypeGroups.find(ptg => {
            if(ptg.quotaProducts.length === quota.products.length) {
              const cpi = ptg.quotaProducts.map(({id}) => id);
              return !quota.products.map(product => cpi.includes(product.id)).includes(false);
            } return false;
          });
          if(!productTypeGroup) {
            productTypeGroup = {
              quotaProducts: quota.products,
              productTypeElements: [quota]
            }
            company.productTypeGroups.push(productTypeGroup);
          } else{
            productTypeGroup.productTypeElements.push(quota);
          }
        }
      });

      companies.forEach(company => {
        tableRows.push({
          name: company.company_name,
          quotas: company.productTypeGroups,
          isFree: this.isFreeQuota(company.company_name),
        });
      });

      let formatList = [];

      if (this.hasTurns) {
        formatList = tableRows.map(item => ({
          ...item,
          quotas: this.getSummaryTurnQuotas(item.quotas.map(({productTypeElements}) => productTypeElements).flat()),
          turns: this.groupQuotasByTurn(item.quotas.map(({productTypeElements}) => productTypeElements).flat()),
          isCompanyTotal: true,
        }));
      } else {
        formatList = tableRows.map(item => ({
          ...item,
          quotas: this.formatQuotasList(item.quotas),
        }));
      }
      // this.getTotalRow(this.quotaList);
      return this.isClient || this.isTransporter ? formatList : [this.getTotalRow(this.quotaList), ...formatList];

      // return this.formatQuotasList(this.quotaList);
    },

    freeQuotas() {
      return this.formattedQuotas.find(quota => quota.isFree).quotas;
    }
  },

  mounted() {
    this.checkButtons();
  },

  methods: {
    groupBy,

    isFreeQuota(quotaCompany) {
      return quotaCompany === this.$t('quotas.Free');
    },

    checkButtons() {
      const BACKSPACE = 20;
      const { quotasTable } = this.$refs;

      if (quotasTable) {
        const { scrollLeft, clientWidth, scrollWidth } = quotasTable;

        this.isShownLeftScrollButton = scrollLeft > BACKSPACE;
        this.isShownRightScrollButton = scrollLeft + clientWidth < scrollWidth - BACKSPACE;
      }
    },

    checkTableScrolled() {
      const { quotasTable } = this.$refs;

      if (quotasTable) {
        this.isTableHorizontalScrolled = Boolean(quotasTable.scrollLeft);
        this.isTableVerticalScrolled = Boolean(quotasTable.scrollTop);
      }
    },

    tableScrolled() {
      this.checkButtons();
      this.checkTableScrolled();
    },

    scrollLeft() {
      const scrollAnimation = setInterval(() => {
        this.$refs.quotasTable.scrollLeft -= 2;
      }, 1);

      setTimeout(() => {
        clearInterval(scrollAnimation);
      }, 300);
    },

    scrollRight() {
      const scrollAnimation = setInterval(() => {
        this.$refs.quotasTable.scrollLeft += 2;
      }, 1);

      setTimeout(() => {
        clearInterval(scrollAnimation);
      }, 300);
    },

    getTotalRow(quotaList) {
      const list = quotaList.map(quota => ({
        ...quota,
        productId: quota.product_id,
      }));
      const gropedByProduct = this.groupByProduct(list);
      const totalQuotas = gropedByProduct.map(item => ({
        ...item,
        isTotalRow: true,
        ...this.calcQuantities(item.quotas),
      }));

      const sortByProducts = this.filteredProducts.map(product => {
        const hasProduct = totalQuotas.find(quota => quota.product_id === product.id);

        if (hasProduct) {
          return hasProduct;
        }
        return {};
      });

      return {
        name: this.$t('Total'),
        isTotal: true,
        quotas: sortByProducts,
      };
    },

    groupByProduct(list) {
      const groupedQuotas = this.groupBy(list, 'productId');

      return Object.keys(groupedQuotas).map(key => ({
        name: key,
        quotas: groupedQuotas[key],
        product_id: groupedQuotas[key][0].product_id || null,
      }));
    },

    calcQuantities(quotas) {
      return {
        quantity_trips: quotas.reduce((acc, curr) => {
          if (curr.quantity_trips) {
            return acc + curr.quantity_trips;
          }

          return acc + curr.sub_quotas.reduce((subQuotaAcc, subQuotaCurr) => subQuotaAcc
            + subQuotaCurr.quantity_trips, 0);
        }, 0),
        quantity_tons: quotas.reduce((acc, curr) => {
          if (curr.quantity_tons) {
            return acc + curr.quantity_tons;
          }

          return acc + curr.sub_quotas.reduce((subQuotaAcc, subQuotaCurr) => subQuotaAcc
            + subQuotaCurr.quantity_tons, 0);
        }, 0),
      };
    },

    groupQuotasByTurn(quotas) {
      const groupedByTurns = this.groupBy(quotas, 'turn');
      const formattedTurnsQoutas = Object.keys(groupedByTurns).map(key => ({
        name: key === 'null' ? this.$t('quotas.Exchange') : `${this.$t('quotas.Exchange')} ${key}`,
        quotas: this.formatQuotasList(groupedByTurns[key]),
      }));

      return formattedTurnsQoutas;
    },

    formatQuotasList(quotas) {
      return this.filteredProducts.map(product => {
        const productsId = product.products.map(({id}) => id);
        const productTypeGroup = quotas.filter(qt => qt.quotaProducts.length === productsId.length)
        .find(qt => !qt.quotaProducts.map(({id}) => id)
          .map(productId => productsId.includes(productId))
          .includes(false)
        );
        if (productTypeGroup) {
          const quota = productTypeGroup.productTypeElements[0];
          quota.quantity_tons = productTypeGroup.productTypeElements.reduce((accum, el) => accum + el.quantity_tons, 0);
          quota.quantity_trips = productTypeGroup.productTypeElements.reduce((accum, el) => accum + el.quantity_trips, 0);
          quota.tripsSum = productTypeGroup.productTypeElements.reduce((accum, el) => accum + el.tripsSum, 0);
          quota.trips_count = productTypeGroup.productTypeElements.reduce((accum, el) => accum + el.trips_count, 0);
          return quota;
        }

        return {
          isEmpty: true,
          company: quotas[0].company,
          product_id: product.id,
          ...product,
          tech_date: quotas[0].tech_date,
          turn: quotas[0].turn,
        };
      });
      // return this.filteredProducts.map(product => {
      //   const hasProduct = quotas.find(quota => quota.product_id === product.id);
      //
      //   if (hasProduct) {
      //     return hasProduct;
      //   }
      //
      //   return {
      //     isEmpty: true,
      //     company: quotas[0].company,
      //     product_id: product.id,
      //     tech_date: quotas[0].tech_date,
      //     turn: quotas[0].turn,
      //   };
      // });
    },

    openProductList(products) {
      if (products.length > 1) {
        this.openedProductsList = !this.openedProductsList;
      }
    }
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';

  .quotas-public-table {
    overflow: auto;
    width: 100%;
    position: relative;
    max-height: calc(100vh - 180px);

    &__preloader {
      left: 50%;
      transform: translateX(-50%);

      &-wrapper {
        position: relative;
      }
    }

    &--hasHorizontalShadow {
      .quotas-public-table__row-col--fixed {
        box-shadow: 5px 0px 8px -3px #e5e5e5;
      }
    }

    &--hasVerticalShadow {
      .quotas-public-table__header-row:last-child {
        .quotas-table__col {
          box-shadow: 0px 6px 5px -3px #f4f4f4;
        }
      }
    }

    &__nodata {
      font-size: 18px;
      line-height: 30px;
      text-align: center;
    }

    &__arrow {
      height: 32px;
      width: 32px;
      border-radius: 0px;
      position: absolute;
      z-index: 5;
      top: 17px;

      &--right {
        right: 16px;
      }

      &--left {
        left: 228px;
      }
    }

    &__wrapper {
      background-color: #fff;
      padding: 16px 16px 16px 24px;
      position: relative;
      height: calc(100vh - 300px);
    }

    &__body {
      display: inline-block;
    }

    &--turns {
      background-color: transparent;
      border: 0px;

      .quotas-public-table__header {
        background-color: #fff;
      }
    }

    &__header {
      font-size: 0;
      margin-bottom: 0px;
      position: sticky;
      left: 0;
      top: 0;
      z-index: 3;

      &-row {
        display: flex;

        .quotas-public-table__col {
          background-color: #fff;
          line-height: 20px;
        }

        &--info {
          .quotas-public-table__col {
            display: flex;
            padding-left: 0;

            &-section {
              width: 50%;
              color: $steel-grey;
              text-align: center;

              &-appoint {
                color: $light-blue;
                text-decoration: underline;
              }
            }
          }
        }

        &:first-child {
          .quotas-public-table__col {
            border-top: 1px solid $light-grey;
          }
        }
      }
    }

    &__company {
      &--turns {
        margin-top: 16px;
        border: 1px solid $light-grey;
        background-color: #fff;
      }

      &--total {
        margin-top: 0;
        background-color: $white-yellow;
        border-top: 0;

        .quotas-public-table__row-col  {
          padding-top: 18px;
          padding-bottom: 18px;
          background-color: $white-yellow;

          .quotas-public-table__col-time {
            margin-bottom: 0px;
          }
        }

        .quotas-public-table__row {
          .quotas-public-table__row-col {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }

        .quotas-public-table__row--turn {
          background-color: #fff;

          .quotas-public-table__row-col--fixed {
            background-color: #fff;
            z-index: 2;
          }
        }

        .quotas-public-table__row-col--fixed {
          background-color: $white-yellow;
          z-index: 2;
        }
      }

      &--free {
        margin-top: 0;
        border-top: 0;
      }
    }

    &__col {
      width: 220px;
      flex-shrink: 0;
      border-right: 1px solid $light-grey;
      border-bottom: 1px solid $light-grey;
      line-height: 24px;
      font-size: 14px;
      font-weight: 600;
      padding: 6px 0px 6px 27px;

      &--fixed {
        position: sticky !important;
        width: 236px;
        border-left: 1px solid $light-grey;
        left: 0;
        background-color: #fff;
        z-index: 2;
      }

      .quotas-public-table__col-section {
        &.header-ts {
          text-align: left;
        }
      }
    }

    &__row {
      display: flex;
      width: auto;

      &-col {
        width: 220px;
        flex-shrink: 0;
        border-right: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;
        padding: 18px 0px;
        background-color: #fff;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $almost-black;

        .quotas-public-table__col {
          &-time {
            color: $steel-grey;
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            min-height: 20px;

            &-counter {
              background-color: $white-yellow;
              padding: 2px;
              border-radius: 100px;
            }
          }

          &-numbers {
            display: flex;
            position: relative;

            &-icon {
              display: none;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -18px;
            }
          }

          &-button {
            font-size: 14px;
            height: 28px;
            width: calc(100% - 16px);
          }
        }

        &--fixed {
          position: sticky !important;
          width: 236px;
          background-color: #fff;
          left: 0;
          padding: 18px 0px;
          font-size: 14px;
          line-height: 24px;
          padding-left: 22px;
          color: $almost-black;
          text-align: left;
          z-index: 2;
          align-items: center;
          display: flex;
          border-left: 1px solid $light-grey;

          .arrow-icon {
            position: absolute;
            z-index: 1;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);

            &.arrow-up {
              left: 17px;
            }
          }
        }

        &--nohover {
          cursor: default;
        }

        &--empty {
          justify-content: flex-end;
          display: flex;
          cursor: default;
          align-items: center;

          .t-button--without-text {
            width: 22px;
            height: 22px;
          }
        }

        &--disabled {
          cursor: default;
        }

        .quotas-public-table__col-section {
          width: 50%;

          &--ts {
            color: $steel-grey;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            text-align: left;
            padding-left: 4px;
          }

          &--quota {
            position: relative;

            span {
              position: relative;
            }
          }

          &-appoint {
            color: $light-blue;
            text-decoration: underline;
            font-size: 14px;
            line-height: 24px;
          }
        }

        &--total .quotas-public-table__col-time {
          min-height: auto;
        }

        &--upper {
          text-transform: uppercase;
        }
      }

      &--turn {
        .quotas-public-table__row-col {
          padding-top: 8px;
          padding-bottom: 10px;

          &--fixed {
            padding-top: 6px !important;
            padding-bottom: 6px !important;
            padding-left: 22px;
            color: $steel-grey;
            font-size: 14px;
            line-height: 24px;
            z-index: 2;
            border-left: 1px solid $light-grey;
          }
        }
      }

      &--companyTotal {
        .quotas-public-table__row-col {
          border-right: 0;
          padding-top: 12px;
          padding-bottom: 12px;
          cursor: default;

          &--fixed {
            display: flex;
            justify-content: space-between;
            box-shadow: none;
          }

          &:last-child {
            border-right: 1px solid $light-grey;
          }
        };
      }
    }
  }

.quotas-table__product-list {
  padding-left: 10px;
}
</style>
