var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('AuthTemplate',{staticClass:"login"},[_c('template',{slot:"heading"},[_vm._v("\n      "+_vm._s(_vm.$t('auth.Log in'))+"\n    ")]),_vm._v(" "),_c('template',{slot:"heading-follower"},[_c('div',{staticClass:"mb-3"},[_c('span',{staticClass:"mr-1"},[_vm._v("\n          "+_vm._s(_vm.$t('auth.New user?'))+"\n        ")]),_vm._v(" "),_c('TLink',{staticClass:"login__link auth-template__major-link",attrs:{"route":"/register","is-underlined":_vm.isLinkUnderlined}},[_vm._v("\n          "+_vm._s(_vm.$t('auth.Create an account'))+"\n        ")])],1)]),_vm._v(" "),_c('template',{slot:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}}},[_c('TInput',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            regex: _vm.regex.phoneRegex,
          }),expression:"{\n            required: true,\n            regex: regex.phoneRegex,\n          }"},{name:"mask",rawName:"v-mask",value:(_vm.$options.phoneMask),expression:"$options.phoneMask"}],staticClass:"login__input",attrs:{"error-messages":_vm.errors.collect('phone'),"type":"tel","name":"phone","label":_vm.$t('Phone number'),"placeholder":"+7 (000) 000-00-00","disabled":_vm.isLoading},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_vm._v(" "),_c('TInput',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            min: [6],
          }),expression:"{\n            required: true,\n            min: [6],\n          }"}],staticClass:"mb-1 login__input login__input--password",attrs:{"error-messages":_vm.errors.collect('password'),"type":_vm.isPasswordShown ? 'text' : 'password',"name":"password","label":_vm.$t('Password'),"has-button":"","disabled":_vm.isLoading},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}},[_c('TButton',{staticClass:"auth-template__password-btn",staticStyle:{"position":"absolute","right":"15px","width":"32px"},attrs:{"slot":"button","transparent":"","icon":_vm.isPasswordShown ? 'eye' : 'eye-input',"icon-fill":_vm.isPasswordShown ? 'none' : '#5E6979',"icon-stroke":_vm.isPasswordShown ? '#5E6979' : 'none',"icon-view-box":_vm.isPasswordShown ? '0 1 17 13' : '0 0 15 17',"icon-width":"15","icon-height":"15"},on:{"click":_vm.showHidePassword},slot:"button"})],1),_vm._v(" "),_c('div',{staticClass:"login__wrapper"},[_c('TLink',{class:_vm.serverError ? 'mb-2' : 'mb-4',attrs:{"route":"/password-recovery","is-underlined":false}},[_vm._v("\n            "+_vm._s(_vm.$t('auth.Forgot your password?'))+"\n          ")])],1),_vm._v(" "),(_vm.serverError)?_c('div',{staticClass:"mb-3 error-text"},[_vm._v("\n          "+_vm._s(_vm.serverError)+"\n        ")]):_vm._e(),_vm._v(" "),_c('TButton',{staticClass:"mb-4 login__button",attrs:{"loading":_vm.isLoading,"type":"submit"}},[_vm._v("\n          "+_vm._s(_vm.$t('auth.Login'))+"\n        ")])],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }