<template>
  <div class="quotas-header">
    <div
      v-if="!isPublicQuotas"
      class="mb-3 quotas-header__top">
      <div class="quotas-header__heading">
        <h1 class="mr-4 quotas-header__heading-tilte">
          {{$t('quotas.Quotas')}}
        </h1>
        <!-- Пока не нужно, возможно понадобится позже -->
        <!-- <TButton
          class="mr-3 quotas-header__heading-reload"
          icon="refresh"
          icon-width="16"
          icon-height="18"
          icon-view-box="0 0 16 18"
          @click="reloadData"
        />
        <span class="quotas-header__heading-text">
          Обновлено 30 секунд назад
        </span> -->
      </div>
      <div>
        <TButton
          v-if="isPublicQuotas && !isLoggedIn"
          class="quotas-header__button"
          @click="$emit('to-login')"
        >
          {{$t('auth.Login or register')}}
        </TButton>
        <TButton
          v-else-if="isEditQuotas && !isPublicQuotas"
          class="quotas-header__button"
          icon="add"
          @click="$emit('add-quota')"
        >
          {{$t('quotas.Create quota')}}
        </TButton>
      </div>
    </div>
    <div class="quotas-header__filters">
      <div class="mr-3 quotas-header__filters-date">
        <TDatepicker
          v-model="filterFields.tech_date"
          :label="$t('Date')"
          has-arrows
          @input="onFilterChange"
        />
      </div>
      <div class="quotas-header__filters-tabs">
        <TButtonGroup
          v-model="filterFields.unit_type"
          :options="quotaTypes"
          @input="onFilterChange(false)"
        />
      </div>
    </div>
    <TTabs
      v-if="!isPublicQuotas"
      v-model="filterFields.direction"
      class="mt-4 quotas-header__direction-tabs"
      @input="onFilterChange(), setTimeslotsDirection($event)"
    >
      <TTab
        :active="filterFields.direction === $options.dqModes.LOADING"
        :label="$t('Loading')"
        :slug="1"
      />
      <TTab
        :active="filterFields.direction === $options.dqModes.UNLOADING"
        :label="$t('Unloading')"
        :slug="2"
      />
    </TTabs>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations, mapActions } from 'vuex';

// Model
import quotaFilterModel from './models/quotaFilterModel';

// mixins
import timeMixin from '~/assets/js/mixins/timeMixin';
import accessMixin from '~/assets/js/mixins/accessMixin';
import routerMixin from '~/assets/js/mixins/routerMixin';
import quotasMixin from '~/assets/js/mixins/quotasMixin';

// Constants
import { LOADING_DQ_ID, UNLOADING_DQ_ID } from '~/assets/js/constants';

export default {
  name: 'QuotasHeader',

  mixins: [
    timeMixin,
    accessMixin,
    routerMixin,
    quotasMixin,
  ],

  dqModes: {
    LOADING: LOADING_DQ_ID,
    UNLOADING: UNLOADING_DQ_ID,
  },

  data() {
    return {
      quotaTypes: [
        { text: this.$t('quotas.Tons'), value: 2 },
        { text: this.$t('quotas.Trips'), value: 1 },
      ],
      filterFields: quotaFilterModel(),
    };
  },

  computed: {
    ...mapGetters({
      quotaUnit: 'quotas/getUnit',
      dqMode: 'main/getDqMode',
      isLoggedIn: 'general/isLoggedIn',
    }),

    isPublicQuotas() {
      return this.$route.name === 'public-quotas';
    },
  },

  created() {
    this.fillFilterFields();
  },

  methods: {
    ...mapActions({
      loadQuotaList: 'quotas/loadQoutaList',
      loadPublicQuotaList: 'quotas/loadPublicQuotaList',
    }),

    ...mapMutations({
      setFilters: 'quotas/setFilters',
      setTimeslotsDirection: 'timeslots/setTimeslotsDirection',
    }),

    fillFilterFields() {
      this.filterFields = quotaFilterModel(this.initialFilters);
      this.setFilters(quotaFilterModel(this.initialFilters));
    },

    onFilterChange(doRequest = true) {
      this.setQuery(this.filterFields);
      this.setFilters(quotaFilterModel(this.filterFields));

      if (doRequest) {
        if (this.isPublicQuotas) {
          this.loadPublicQuotaList(this.filterFields);
        } else {
          this.loadQuotaList(this.filterFields);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/_variables';

.quotas-header {
  padding: 24px;
  padding-bottom: 0;
  margin-bottom: 24px;
  background-color: #fff;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    display: flex;
    align-items: center;

    &-tilte {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__filters {
    display: flex;
    align-items: flex-end;

    &-date {
      width: 253px;

      .t-field {
        padding-bottom: 0;
      }

      .t-datepicker__input {
        font-weight: 600;
        font-size: 18px;
        padding: 0 0 0 50px !important;
      }
    }

    &-tabs {
      width: 300px;
    }
  }

  &__direction-tabs {
    display: flex;
    border: 1px solid $light-grey;
    box-shadow: 0 3px 6px $grey-shadow;
    height: 44px;
    background-color: #FFF;
    z-index: 1;
    margin-left: -24px;
    margin-right: -24px;

    .t-tabs {
      flex: 1;

      &__header {
        flex: 1;
        display: flex;
      }

      &__button {
        flex: 1;
        flex-grow: 1;
        text-transform: uppercase;
      }
    }
  }
}
</style>
